import { Modal, Button, notification } from "antd";
import { useState } from "react";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

import endpoints from "../../utils/endpoints.js";

function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.faculty.delete, {
        data: { id: data.id },
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Palestrante foi apagado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleCloseModal() {
    close();
  }

  return (
    <Modal
      className="modal-antd-speaker"
      title={`Apagar palestrante`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Apagar
        </Button>,
        <Button size="large" onClicdk={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <p>Tem a certeza que quer apagar o palestrante:</p>
      <p className="bold">{data.name}</p>
    </Modal>
  );
}

export default Delete;
