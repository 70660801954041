import { Row, Col, Divider, Tabs, Form, notification, Switch, Spin, Button } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../utils/config.js";
import SettingsVersion from "./SettingsVersion";

import "react-quill/dist/quill.snow.css";
import "./Settings.css";
import endpoints from "../../utils/endpoints.js";
import SettingsPosters from "./SettingsPosters.js";
import SettingsCommunications from "./SettingsCommunications.js";
import { ReloadOutlined } from "@ant-design/icons";

function Settings() {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        console.log(res);
        let settingsDataPrivateAccess = res.data.filter((item) => item.name === "private_access")[0];
        form.setFieldsValue(JSON.parse(settingsDataPrivateAccess.json));

        setSettings(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleSubmit(values) {
    setIsLoading(true);
    setIsButtonLoading(true);

    axios
      .post(endpoints.settings.update, {
        data: { values: values, name: "private_access" },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        setIsLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Acesso privado foi editado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  return (
    <div className="dashboard_page">
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Definições</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Form form={form} id="add-commission_type-form" layout="horizontal" onFinish={handleSubmit}>
            <Form.Item className="m-0" name="private_access" label="Dar acesso ao perfil" valuePropName="checked">
              <Switch disabled={isButtonLoading} onChange={form.submit} size="large" />
            </Form.Item>
          </Form>
          <Button size="large" onClick={handleGetData} className="ml-10">
            <ReloadOutlined />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            <Tabs
              type="card"
              items={[
                {
                  label: `Versão`,
                  key: "1",
                  children: <SettingsVersion data={settings} />,
                },
                {
                  label: `Posters`,
                  key: "2",
                  children: <SettingsPosters data={settings} />,
                },
                {
                  label: `Comunicações`,
                  key: "3",
                  children: <SettingsCommunications data={settings} />,
                },
              ]}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Settings;
