import { Row, Col, Divider, Button, Spin, notification, Upload, Form, Segmented, Input } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import { DeleteOutlined, FileImageOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

import "./WelcomeLetter.css";

import "react-quill/dist/quill.snow.css";

const { Dragger } = Upload;

function Posters() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [optionSelected, setOptionSelected] = useState("SPN");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.welcomeLetter.read)
      .then((res) => {
        console.log(res);
        let auxData = res.data;

        for (let i = 0; i < auxData.length; i++) {
          if (auxData[i].signatures && typeof auxData[i].signatures === "string") {
            auxData[i].signatures = JSON.parse(auxData[i].signatures);
          }
        }

        console.log(auxData);

        setData(auxData);
        form.setFieldsValue(auxData.filter((item) => item.type === optionSelected)[0]);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleSubmit(values) {
    console.log(values);
    //setIsLoading(true);
    //setIsButtonLoading(true);

    if (values.signatures) {
      for (let i = 0; i < values.signatures.length; i++) {
        console.log(values.signatures[i]);
        if (values.signatures[i].image && values.signatures[i].image.fileList && values.signatures[i].image.fileList.length > 0) {
          values.signatures[i].image = values.signatures[i].image.fileList[0].response;
        } else {
          values.signatures[i].image = values.signatures[i].image;
        }
      }

      values.signatures = JSON.stringify(values.signatures);
    }

    axios
      .post(endpoints.welcomeLetter.update, {
        data: values,
      })
      .then((res) => {
        notification.success({
          message: "Sucesso!",
          description: "Carta de boas-vindas foi editada com sucesso!",
        });
        setIsLoading(false);
        setIsButtonLoading(false);
        handleGetData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleChangeSegment(value) {
    setIsLoading(true);
    console.log(data.filter((item) => item.type === value)[0]);
    form.setFieldsValue(data.filter((item) => item.type === value)[0]);
    setOptionSelected(value); // string
    setIsLoading(false);
  }

  return (
    <div className="dashboard_page">
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={20} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Carta de boas-vindas</h3>
        </Col>
        <Col span={4} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            <Segmented
              value={optionSelected}
              options={[
                {
                  label: <img src={spnLogo} />,
                  value: "SPN",
                },
                {
                  label: <img src={apedtLogo} />,
                  value: "APEDT",
                },
              ]}
              onChange={handleChangeSegment}
            />

            <Row className="dashboard_content_row">
              <Col span={24}>
                <Form form={form} id="add-moderator-form" layout="vertical" onFinish={handleSubmit}>
                  <Form.Item hidden name="id">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Texto" name="text">
                    <ReactQuill className="welcome_letter" />
                  </Form.Item>
                  <Form.List name="signatures">
                    {(fields, { add, remove, move }, { errors }) => (
                      <Row className="w-100" gutter={[24]}>
                        {fields.map((field, index) => {
                          return (
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues["signatures"] !== currentValues["signatures"]}>
                              {({ getFieldValue }) => {
                                console.log(getFieldValue("signatures"));
                                return (
                                  <Col span={8}>
                                    <Form.Item name={[index, "image"]} label="Imagem">
                                      <Dragger
                                        accept="image/*"
                                        multiple={false}
                                        maxCount={1}
                                        defaultFileList={
                                          getFieldValue("signatures")[index]?.image
                                            ? [
                                                {
                                                  name:
                                                    getFieldValue("signatures")[index]?.image.fileList && getFieldValue("signatures")[index]?.image.fileList.length > 0
                                                      ? getFieldValue("signatures")[index]?.image.fileList[0].response
                                                      : getFieldValue("signatures")[index]?.image,
                                                },
                                              ]
                                            : []
                                        }
                                        action={config.server_ip + endpoints.welcomeLetter.upload}
                                        className={`dragger ${getFieldValue("signatures")[index]?.image ? "hasPreview" : ""}`}
                                      >
                                        <p className="ant-upload-drag-icon">
                                          <FileImageOutlined
                                            style={{
                                              color: "rgb(103 103 103)",
                                            }}
                                          />
                                        </p>
                                        <p className="f-18 mb-0">
                                          <b>Upload da imagem</b>
                                        </p>
                                        <p className="mt-0">Faça Drag and drop aqui</p>
                                        <div
                                          className={`preview_file ${getFieldValue("signatures")[index]?.image ? "hasPreview" : ""}`}
                                          style={{
                                            backgroundImage: `url(${
                                              getFieldValue("signatures")[index]?.image
                                                ? `${config.server_ip}/images/${
                                                    getFieldValue("signatures")[index]?.image.fileList && getFieldValue("signatures")[index]?.image.fileList.length > 0
                                                      ? getFieldValue("signatures")[index]?.image.fileList[0].response
                                                      : getFieldValue("signatures")[index]?.image
                                                  }`
                                                : null
                                            })`,
                                          }}
                                        ></div>
                                      </Dragger>
                                    </Form.Item>
                                    <Form.Item label="Nome" name={[index, "name"]}>
                                      <Input size="large" />
                                    </Form.Item>
                                    <Form.Item label="Posição" name={[index, "job_title"]}>
                                      <Input size="large" />
                                    </Form.Item>
                                    <Form.Item className="w-100">
                                      <Button type="dashed" onClick={() => remove(index)} className="w-100" size="large" icon={<DeleteOutlined />}>
                                        Apagar
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                );
                              }}
                            </Form.Item>
                          );
                        })}
                        <Col span={24}>
                          <Button type="dashed" onClick={() => add()} className="w-100" size="large" icon={<PlusOutlined />}>
                            Adicionar assinatura
                          </Button>
                        </Col>
                        <Form.ErrorList errors={errors} />
                      </Row>
                    )}
                  </Form.List>
                  <Row className="mt-20 d-flex jc-center ai-center">
                    <Col span={24}>
                      <Button loading={isButtonLoading} size="large" type="primary" onClick={form.submit}>
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Posters;
