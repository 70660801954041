import { Row, Col, Button, Divider, Pagination, Spin, Form, Input, Empty, Segmented } from "antd";
import { EditOutlined, DeleteOutlined, LinkOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import Create from "../../components/Sponsors/Create";
import Update from "../../components/Sponsors/Update";
import Delete from "../../components/Sponsors/Delete";

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

function Sponsors() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [sponsorsType, setSponsorsType] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  const [optionSelected, setOptionSelected] = useState("sponsors");

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsor.read)
      .then((res) => {
        console.log(res);
        setData(res.data[0]);
        setSponsorsType(res.data[1]);
        handlePrepareData(res.data[0]);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handlePrepareData(items) {
    let auxArray = [];
    for (let i = 0; i < items.length; i++) {
      let arrayData = items[i];
      console.log();

      let aux = {
        key: i,
        number: i + 1,
        img: <img className="w-100" src={`${config.server_ip}/images/${arrayData.img}`} />,
        type: arrayData.type,
        name: arrayData.name,
        link: <LinkOutlined className="link-action" onClick={() => window.open(arrayData.link)} />,
        tier_name: arrayData.tier_name,
        pos: arrayData.pos,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(arrayData)} />
            <DeleteOutlined className="delete-action" onClick={() => handleOpenDelete(arrayData)} />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleChangePage(e, p) {
    setCurrentPage(e);
    setItemsPerPage(p);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(p);
    } else {
      let newMinValue = p * (e - 1);
      let newMaxValue = newMinValue + p;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(values) {
    const newData = data.filter((item) => item.name.toLowerCase().includes(values.search.toLowerCase()));
    handlePrepareData(newData);
  }

  function handleChangeSegment(value) {
    setIsLoading(true);
    setOptionSelected(value); // string
    console.log(tableData);
    setIsLoading(false);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} sponsorsType={sponsorsType} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} sponsorsType={sponsorsType} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Patrocinadores</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Form form={form} onValuesChange={handleSearch} className="mr-10">
            <Form.Item name="search" noStyle>
              <Input prefix={<SearchOutlined />} className="mr-20" size="large" placeholder="Procurar..." allowClear />
            </Form.Item>
          </Form>
          <Button size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  <Segmented
                    className="mb-20"
                    value={optionSelected}
                    options={[
                      {
                        label: "Patrocinadores",
                        value: "sponsors",
                      },
                      {
                        label: "Patrocinadores Científicos",
                        value: "scientific",
                      },
                    ]}
                    onChange={handleChangeSegment}
                  />
                  {tableData.filter((item) => item.type === optionSelected).length > 0 ? (
                    tableData
                      .filter((item) => item.type === optionSelected)
                      .slice(minValue, maxValue)
                      .map((item) => {
                        return (
                          <Row className="table_item">
                            <Col span={2}>
                              <div className="d-flex jc-center ai-center mr-20">{item.img}</div>
                            </Col>
                            <Col span={6}>
                              <div className="d-flex flex-column">
                                <span className="f-12">Nome</span>
                                <span className="f-18 semi-bold">{item.name}</span>
                              </div>
                            </Col>
                            <Col span={4}>
                              <div className="d-flex flex-column">
                                <span className="f-12">Tipo</span>
                                <span className="f-18 semi-bold">{item.tier_name}</span>
                              </div>
                            </Col>
                            <Col span={2}>
                              <div className="d-flex flex-column">
                                <span className="f-12">Posição</span>
                                <span className="f-18 semi-bold">{item.pos}</span>
                              </div>
                            </Col>
                            <Col span={8} className="d-flex jc-center ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Link</span>
                                <span className="d-flex ai-center">{item.link}</span>
                              </div>
                            </Col>
                            <Col span={2} className="d-flex jc-end ai-center">
                              {item.actions}
                            </Col>
                          </Row>
                        );
                      })
                  ) : (
                    <Row className="d-flex jc-center ai-center empty_data">
                      <Col span={24} className="d-flex flex-column jc-center ai-center">
                        <Empty description="Sem dados a apresentar" />
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={tableData.filter((item) => item.type === optionSelected).length}
                  />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Sponsors;
