import { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="layout-content">
          <Spin size="large" />
        </div>
      ) : (
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              {/*<Card bordered={false} className="criclebox h-full">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Monthly Saved</span>
                    <Title level={3}>
                    12
                    <small className='positive'>
                    </small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                      <HeartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>*/}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Home;
