import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfigProvider, Spin } from "antd";
import axios from "axios";

import Home from "./pages/Home/Home";
import Program from "./pages/Program/Program.js";
import Commission from "./pages/Commission/Commission";
import Sponsors from "./pages/Sponsors/Sponsors";
import SponsorsType from "./pages/Sponsors/SponsorsType";
import Login from "./pages/Login/Login";
import WelcomeLetter from "./pages/WelcomeLetter/WelcomeLetter";
import CommissionType from "./pages/Commission/CommissionType.js";
import CommissionPosition from "./pages/Commission/CommissionPosition.js";
import Notifications from "./pages/Notifications/Notifications";
import Settings from "./pages/Settings/Settings";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Main from "./layout/Main/Main";

import "./assets/styles/global.css";
import "./index.css";
import api from "./utils/api.js";
import Faculty from "./pages/Faculty/Faculty.js";
import Rooms from "./pages/Rooms/Rooms.js";
import Users from "./pages/Users/Users.js";

api.axiosCreate();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#061848",
          fontFamily: "Poppins",
        },
      }}
    >
      <div className={`App ${window.location.pathname.includes("/admin") ? "admin" : ""}`}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<Main handleLogout={handleLogout} />}>
            <Route index exact path="/" element={<Navigate to={`/program`} replace />} />
            <Route exact path="/login" element={<Navigate to={`/program`} replace />} />
            <Route exact path="/dashboard" element={<Home />} />
            <Route exact path="/program" element={<Program />} />
            <Route exact path="/room" element={<Rooms />} />
            <Route exact path="/faculty" element={<Faculty />} />
            <Route exact path="/commission" element={<Commission />} />
            <Route exact path="/commission-type" element={<CommissionType />} />
            <Route exact path="/commission-position" element={<CommissionPosition />} />
            <Route exact path="/sponsors" element={<Sponsors />} />
            <Route exact path="/sponsors-type" element={<SponsorsType />} />
            <Route exact path="/welcome-letter" element={<WelcomeLetter />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/users" element={<Users />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
