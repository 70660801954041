import { Row, Col, Button, Spin, Divider, Pagination, Form, Input, Empty } from "antd";
import { EditOutlined, DeleteOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import Create from "../../components/Commission/Create";
import Update from "../../components/Commission/Update";
import Delete from "../../components/Commission/Delete";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";

import endpoints from "../../utils/endpoints";
import optionsType from "../../utils/optionsType";

import "./Commission.css";

function Commission() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [commissionPosition, setCommissionPosition] = useState([]);
  const [commissionType, setCommissionType] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.commission.read)
      .then((res) => {
        setData(res.data[0]);
        setCommissionPosition(res.data[1]);
        setCommissionType(res.data[2]);

        handlePrepareData(res.data[0]);

        if (form.getFieldValue("search")) {
          handleSearch(form.getFieldsValue());
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(array) {
    setIsLoading(true);
    let auxData = [];

    for (let i = 0; i < array.length; i++) {
      let arrayData = array[i];

      let aux = {
        key: i,
        id: arrayData.id,
        name: arrayData.name,
        commission_type_id: arrayData.commission_type_id,
        commission_type: arrayData.commission_type_name,
        commission_position_id: arrayData.commission_position_id,
        commission_position: arrayData.commission_position_name,
        work: arrayData.work,
        type: <img className="logo-program-table" src={arrayData.type === "SPN" ? spnLogo : apedtLogo} />,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(arrayData)} />
            <DeleteOutlined className="delete-action" onClick={() => handleOpenDelete(arrayData)} />
          </div>
        ),
      };

      auxData.push(aux);
    }

    setTableData(auxData);
    setIsLoading(false);
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(values) {
    const newData = data.filter((item) => item.name.toLowerCase().includes(values.search.toLowerCase()));
    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} optionsType={optionsType} commissionType={commissionType} commissionPosition={commissionPosition} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} optionsType={optionsType} commissionType={commissionType} commissionPosition={commissionPosition} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={16} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Comissão</h3>
        </Col>
        <Col span={8} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Form form={form} onValuesChange={handleSearch} className="mr-10">
            <Form.Item name="search" noStyle>
              <Input prefix={<SearchOutlined />} className="mr-20" size="large" placeholder="Procurar..." allowClear />
            </Form.Item>
          </Form>
          <Button size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row gutter={[24, 0]} className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Tipo de Comissão</span>
                            <span className="f-18">{item.commission_type}</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Posição</span>
                            <span className="f-18">{item.commission_position}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-center ai-center">
                          {item.type}
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Commission;
