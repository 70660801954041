import { Row, Col, Button, Input, Form, notification, Drawer, Upload } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { useState } from "react";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import upload from "../../utils/upload.js";

const { Dragger } = Upload;

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  async function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    for (const file of fileList) {
      let compressedFile = await upload.compress(file);
      formData.append("file", compressedFile);
    }

    formData.append("data", JSON.stringify(values));

    fetch(config.server_ip + endpoints.room.create, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        close({ id: resData.insertId, name: values.name });
        setFilePreview("");
        setFileList([]);
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Sucesso!",
          description: "Moderador foi adicionado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-moderator"
      title={`Adicionar sala`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={handleCloseDrawer}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-moderator-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Row gutter={[24]}>
          <Col span={24}>
            <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile}>
              <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">Clique ou arraste a imagem para esta zona para upload</p>
                <div
                  className={`preview_file ${filePreview ? "hasPreview" : ""}`}
                  style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/${filePreview}`})` }}
                ></div>
              </Dragger>
            </Form.Item>
            <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
              <Input size="large" placeholder="Nome..." />
            </Form.Item>
            <Form.Item name="description" label="Descrição">
              <Input size="large" placeholder="Descrição..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Create;
