import { Button, Input, Form, notification, Drawer, Upload, Select, InputNumber } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { useState } from "react";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import upload from "../../utils/upload.js";

const { Dragger } = Upload;

function Create({ open, close, sponsorsType }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = Form.useForm();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      const compressedFile = upload.compress(file);
      console.log(compressedFile);
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  async function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    for (const file of fileList) {
      let compressedFile = await upload.compress(file);
      formData.append("file", compressedFile);
    }

    formData.append("data", JSON.stringify(values));

    fetch(config.server_ip + endpoints.sponsor.create, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        close();
        setFilePreview("");
        setFileList([]);
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Sucesso!",
          description: "Patrocinador foi adicionado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-sponsor"
      title={`Adicionar patrocinador`}
      open={open}
      onClose={close}
      maskClosable={false}
      extra={[
        <Button className="mr-10" size="large" type="primary" onClick={form.submit} loading={isButtonLoading} disabled={isButtonLoading}>
          Adicionar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} id="add-speaker-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
        <Form.Item name="img" label="Imagem" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Dragger {...uploadProps} className={`dragger ${filePreview ? "hasPreview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <FileImageOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.</p>
            <div
              className={`preview_file ${filePreview ? "hasPreview" : ""}`}
              style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}/images/${filePreview}`})` }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item name="type" label="Tipo" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Select
            showSearch
            allowClear
            size="large"
            placeholder="Tipo de patrocinador..."
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
            options={[
              { value: "sponsors", label: "Patrocinador" },
              { value: "scientific", label: "Patrocinador Científico" },
            ]}
          />
        </Form.Item>
        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <Input size="large" placeholder="Nome..." />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
          {({ getFieldValue }) => (
            <Form.Item name="tier_id" label="Tier" rules={[{ required: getFieldValue("type") === "sponsors" ? true : false, message: "Este é um campo obrigatório" }]}>
              <Select size="large" placeholder="Tipo de patrocinador..." options={sponsorsType.map((value, index) => ({ value: value.id, label: value.name }))}></Select>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item name="pos" label="Posição (nº)" rules={[{ required: true, message: "Este é um campo obrigatório" }]}>
          <InputNumber placeholder="1" size="large" min={1} />
        </Form.Item>
        <Form.Item name="link" label="Link">
          <Input size="large" placeholder="Link..." />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
