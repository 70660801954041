import { Row, Col, Modal, Button, notification } from "antd";
import { useState } from "react";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";

function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.commission.delete, {
        data: data,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Membro da comissão foi apagado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleCloseModal() {
    close();
  }

  return (
    <Modal
      className="modal-antd-commission"
      title={`Apagar membro`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button danger icon={<DeleteOutlined />} size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Apagar
        </Button>,
        <Button size="large" onClicdk={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p>Tem a certeza que quer apagar o membro:</p>
          <p className="bold">{data.name}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Delete;
