import { Row, Col, Button, notification, Divider, Pagination, Spin, Empty } from "antd";
import { EditOutlined, DeleteOutlined, SendOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import endpoints from "../../utils/endpoints";

import "./Users.css";
import dayjs from "dayjs";
import config from "../../utils/config";

function Users() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.user.read)
      .then((res) => {
        console.log(res);
        let auxData = [];
        for (let i = 0; i < res.data.length; i++) {
          let arrayData = res.data[i];
          let aux = {
            key: i + 1,
            id: arrayData.id,
            img: arrayData.img,
            name: arrayData.name,
            email: arrayData.email,
            institution: arrayData.institution,
            job_title: arrayData.job_title,
            actions: <div className="actions-buttons-div"></div>,
          };

          auxData.push(aux);
        }

        setTableData(auxData);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleSend(item) {
    axios
      .post(endpoints.notification.send, {
        data: item,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangePage(e, p) {
    setCurrentPage(e);
    setItemsPerPage(p);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(p);
    } else {
      let newMinValue = p * (e - 1);
      let newMaxValue = newMinValue + p;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }

  return (
    <div className="dashboard_page">
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Utilizadores</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Button size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col xs="24" xl={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={2}>
                          <div className="d-flex flex-column">
                            <div
                              className="user-img"
                              style={{ backgroundImage: item.img ? `url(${config.server_ip}/images/${item.img})` : `url(${config.server_ip}/images/User-default.svg)` }}
                            ></div>
                          </div>
                        </Col>
                        <Col span={5}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={7} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">E-mail</span>
                            <span className="d-flex ai-center">{item.email}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Instituição</span>
                            <span className="d-flex ai-center">{item.institution}</span>
                          </div>
                        </Col>
                        <Col span={4} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Departamento</span>
                            <span className="d-flex ai-center">{item.job_title}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Users;
