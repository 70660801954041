import { Row, Col, Button, Divider, Spin, Empty, Pagination } from "antd";
import { EditOutlined, InboxOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import CreateType from "../../components/Sponsors/CreateType";
import UpdateType from "../../components/Sponsors/UpdateType";

import config from "../../utils/config";
import endpoints from "../../utils/endpoints";

function SponsorsType() {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.sponsor.readType)
      .then((res) => {
        let auxData = [];
        for (let i = 0; i < res.data.length; i++) {
          let arrayData = res.data[i];
          let aux = {
            key: i,
            number: i + 1,
            name: arrayData.name,
            actions: (
              <div className="actions-buttons-div">
                <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(arrayData)} />
              </div>
            ),
          };

          auxData.push(aux);
        }

        setData(res.data);
        setTableData(auxData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleChangePage(e, p) {
    setCurrentPage(e);
    setItemsPerPage(p);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(p);
    } else {
      let newMinValue = p * (e - 1);
      let newMaxValue = newMinValue + p;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  return (
    <div className="dashboard_page">
      <CreateType open={isOpenCreate} close={handleCloseCreate} />
      <UpdateType data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Tipos de patrocinador</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleGetData} className="mr-10">
            <ReloadOutlined />
          </Button>
          <Button size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={1}>
                          <div className="d-flex jc-center ai-center mr-20">{item.number}</div>
                        </Col>
                        <Col span={21}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Nome</span>
                            <span className="f-18 semi-bold">{item.name}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={tableData.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty description="Sem dados a apresentar" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default SponsorsType;
