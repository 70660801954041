import { Row, Col, Modal, Button, notification } from "antd";
import { useState } from "react";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

import endpoints from "../../utils/endpoints.js";

function Delete({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleDelete() {
    setIsButtonLoading(true);

    axios
      .post(endpoints.sponsor.delete, {
        data,
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        notification.success({
          message: "Sucesso!",
          description: "Patrocinador foi apagado com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  return (
    <Modal
      className="modal-antd-commission"
      title={`Apagar patrocinador`}
      open={open}
      onCancel={close}
      footer={[
        <Button danger icon={<DeleteOutlined />} className="mr-20" size="large" type="primary" onClick={handleDelete} loading={isButtonLoading} disabled={isButtonLoading}>
          Apagar
        </Button>,
        <Button size="large" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p>Tem a certeza que quer apagar o patrocinador:</p>
          <p className="bold">{data.name}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Delete;
